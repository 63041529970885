import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Auto-trahison" />
    <h3 className='underline-title'>Auto-trahison</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Depuis que l'aguichante ARIMA<br />
        Fille du pauvre NIZAMARA<br />
        Brouilla en mon coeur lardé<br />
        Le rayon vital de LOLANGO (1)<br />
        Aucun autre coup n'y détona<br />
        Dégoût blasement désormais <br />
        S'empara de mon coeur pétrifié<br />
      </p>
      <p>
        O Chibalonza la fortuite <br />
        Biche hésitante <br />
        Gazelle au flair novice <br />
        Flirtant candidement <br />
        Avec fauve anesthésié <br />
      </p>
      <p>
        O Chibalonza des montagnes <br />
        Si tu savais Chiba <br />
        Tu ne braquerais jamais <br />
        Ton laser à la lumiere monochrome <br />
        Sur un coeur déclassé <br />
      </p>
      <p>
        O Chiba des Chibalonza<br />
        Si tu savais encore Chiba<br />
        Les dégâts que causent <br />
        Les ondulations fascinantes <br />
        De ta machine innocente <br />
        Sur ma carcasse croulante <br />
        Tu te pâmerais sans doute <br />
      </p>
      <p>
        O engrenages à la force de l'aimant:<br />
        La rondeur de ta poitrine<br />
        <span className="par-starting"></span>- poignarde avec furie <br />
        La pureté de ta face racée <br />
        <span className="par-starting"></span>- enivre avec folie <br />
        Tes dents de miroir <br />
        <span className="par-starting"></span>- enflamment avec rutilance <br />
        Ton regard duveté<br />
        <span className="par-starting"></span>- saoule avec douceur <br />
        La volupté freinée de tes yeux <br />
        <span className="par-starting"></span>- déroute mon sens aguerri <br />
        Ton sourire de Mona Lisa <br />
        <span className="par-starting"></span>- aguiche mon système nerveux <br />
        Ta voix hypnogène<br />
        <span className="par-starting"></span>- endort mon esprit embrumé<br />
        Tes paroles naives<br />
        <span className="par-starting"></span>- haussent ma tension artérielle <br />
        Tes jambes galbées <br />
        <span className="par-starting"></span>- pietinent mon corps courbaturé<br />
        Tes bras dodus<br />
        <span className="par-starting"></span>- giflent ma figure fripée<br />
        les roulements rythimiques de tes hanches<br />
        <span className="par-starting"></span>- bloquent les pulsatins de mon coeur martyrisé<br />
      </p>
      <p>
        O fille des Morts<br />
        Tu n'es pas que spécieuse <br />
        <span className="par-starting"></span>Chibalonza<br />
        De ton velours irradient encore <br />
        Tes précieuses vertus adolescentes <br />
      </p>
      <p>
        O fille d'altitude <br />
        Franche Chibalonza<br />
        <span className="par-starting"></span>diamant de la joaillerie <br />
        Détonante Chibalonza<br />
        <span className="par-starting"></span>obus à la mélinite <br />
        Attirante Chibalonza<br />
        <span className="par-starting"></span>nord magnétique au Pôle boréal <br />
        O Chiba de Buhendwa-le-Mushi!<br />
        Virginale comme lys<br />
        Authentique comme Zaïre<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(1) l'amour (en Kimongo)</p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
